import './style.css'
import * as THREE from 'three'
import { OrbitControls } from 'three/examples/jsm/controls/OrbitControls.js'
import { GLTFLoader } from 'three/examples/jsm/loaders/GLTFLoader.js'
import * as dat from 'dat.gui'
/* import {EffectComposer} from 'three/examples/jsm/postprocessing/EffectComposer.js'
import {RenderPass} from 'three/examples/jsm/postprocessing/RenderPass.js'
import {UnrealBloomPass} from 'three/examples/jsm/postprocessing/UnrealBloomPass.js' */
import { gsap } from 'gsap'

/**
 * Loaders
 */

let sceneReady = false
 const loadingBarElement = document.querySelector('.loading-bar')
 const loadingManager = new THREE.LoadingManager(
   // Loaded
   function(){
      // Wait a little
      window.setTimeout(() =>
      {
          // Animate overlay
          gsap.to(overlayMaterial.uniforms.uAlpha, { duration: 3, value: 0, delay: 1 })

          // Update loadingBarElement
          loadingBarElement.classList.add('ended')
          loadingBarElement.style.transform = ''
      }, 500)

      window.setTimeout(() =>
      {
          sceneReady = true;
      }, 3000)
   },
   // Progress
   function(itemUrl, itemsLoaded, itemsTotal){
     const progressRatio = itemsLoaded / itemsTotal;
     loadingBarElement.style.transform = `scaleX(${progressRatio})`
     console.log(progressRatio)
   }
 )
 const gltfLoader = new GLTFLoader(loadingManager)
 const cubeTextureLoader = new THREE.CubeTextureLoader(loadingManager)
/**
 * Base
 */
// Debug
const gui = new dat.GUI()

// Canvas
const canvas = document.querySelector('canvas.webgl')

// Scene
const scene = new THREE.Scene()

// Overlay
const overlayGeometry = new THREE.PlaneBufferGeometry(2,2,1,1)
const overlayMaterial = new THREE.ShaderMaterial({
  transparent: true,
  uniforms: {
    uAlpha: { value: 1 }
  },
  vertexShader: `
  void main(){
    gl_Position = vec4(position, 1.0);
  }
  `,
  fragmentShader:`
  uniform float uAlpha;
  void main() {
    gl_FragColor = vec4( 0.0, 0.0, 0.0, uAlpha);
  }
  `
})
const overlay = new THREE.Mesh(overlayGeometry, overlayMaterial)
scene.add(overlay)


/**
 * Update all materials
 */
const updateAllMaterials = () =>
{
    scene.traverse((child) =>
    {
        if(child instanceof THREE.Mesh && child.material instanceof THREE.MeshStandardMaterial)
        {
            child.material.envMapIntensity = 5
            child.material.needsUpdate = true
            child.castShadow = true
            child.receiveShadow = true
        }
    })
}

/**
 * Environment map
 */
const environmentMap1 = cubeTextureLoader.load([
    '/textures/environmentMaps/1/px.png',
    '/textures/environmentMaps/1/nx.png',
    '/textures/environmentMaps/1/py.png',
    '/textures/environmentMaps/1/ny.png',
    '/textures/environmentMaps/1/pz.png',
    '/textures/environmentMaps/1/nz.png'
])
const environmentMap2 = cubeTextureLoader.load([
  '/textures/environmentMaps/0/px.png',
  '/textures/environmentMaps/0/nx.png',
  '/textures/environmentMaps/0/py.png',
  '/textures/environmentMaps/0/ny.png',
  '/textures/environmentMaps/0/pz.png',
  '/textures/environmentMaps/0/nz.png'
])

environmentMap1.encoding = THREE.sRGBEncoding
environmentMap1.encoding = THREE.sRGBEncoding

//scene.background = environmentMap1
scene.background = new THREE.Color(0x000000)
scene.environment = environmentMap1


/**
 * Models
 */
gltfLoader.load(
    '/models/car/Audi_baked_floor.glb',
    (gltf) =>
    {
        gltf.scene.scale.set(2, 2, 2)
        gltf.scene.rotation.y = Math.PI * 0.5
        gltf.scene.position.y = -0.5
        
        scene.add(gltf.scene)

        updateAllMaterials()
    }
)

// POINTS OF INTEREST
const raycaster = new THREE.Raycaster()

const points = [
  {
      position: new THREE.Vector3(1.55, 0.3, - 0.6),
      element: document.querySelector('.point-0')
  },
  {
      position: new THREE.Vector3(0.5, 0.8, - 1.6),
      element: document.querySelector('.point-1')
  },
  {
      position: new THREE.Vector3(1.6, - 1.3, - 0.7),
      element: document.querySelector('.point-2')
  }
]
/**
 * Lights
 */

//const hemisphereLight = new THREE.HemisphereLight(0xffffff, 0xf6f2f2 , 3.5)
const hemisphereLight = new THREE.HemisphereLight(0xc9b178, 0xb39fac, 2)
scene.add(hemisphereLight)
gui.add(hemisphereLight, 'intensity').min(0).max(7).step(0.1).name('hemisphere_intensity')

const directionalLight = new THREE.DirectionalLight('#c9b178', 5)
directionalLight.castShadow = true
directionalLight.shadow.mapSize.set(1024, 1024)
directionalLight.shadow.camera.far = 20
directionalLight.shadow.normalBias = 0.03
directionalLight.position.set(-0.5, 3, 1.4)
scene.add(directionalLight)

gui.add(directionalLight, 'intensity').min(0).max(7).step(0.1).name('spotlight_intensity')
gui.add(directionalLight.position, 'x').min(-10).max(10).step(0.1)
gui.add(directionalLight.position, 'y').min(0).max(10).step(0.1)
gui.add(directionalLight.position, 'z').min(-10).max(10).step(0.1)
/**
 * Sizes
 */
const sizes = {
    width: window.innerWidth,
    height: window.innerHeight
}

window.addEventListener('resize', () =>
{
    // Update sizes
    sizes.width = window.innerWidth
    sizes.height = window.innerHeight

    // Update camera
    camera.aspect = sizes.width / sizes.height
    camera.updateProjectionMatrix()

    // Update renderer
    renderer.setSize(sizes.width, sizes.height)
    renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))
})

/**
 * Camera
 */
// Base camera
const camera = new THREE.PerspectiveCamera(45, sizes.width / sizes.height, 0.1, 100)
camera.position.set(6, 1.8, - 4)
scene.add(camera)

// Controls
const controls = new OrbitControls(camera, canvas)
controls.enableDamping = true
controls.minDistance = 5
controls.maxDistance = 12
controls.maxPolarAngle = Math.PI / 2 - 0.1

/**
 * Renderer
 */
const renderer = new THREE.WebGLRenderer({
    canvas: canvas,
    antialias: true
})
renderer.shadowMap.enabled = true
renderer.shadowMap.type = THREE.PCFShadowMap
renderer.physicallyCorrectLights = true
renderer.outputEncoding = THREE.sRGBEncoding
renderer.toneMapping = THREE.ReinhardToneMapping
renderer.toneMappingExposure = 1.5
renderer.setSize(sizes.width, sizes.height)
renderer.setPixelRatio(Math.min(window.devicePixelRatio, 2))


// -------- POST PROCESSING

let renderTargetClass = null

if (renderer.getPixelRatio() === 1  && renderer.capabilities.isWebGL2) {
  renderTargetClass = THREE.WebGLMultisampleRenderTarget
}
else {
  renderTargetClass = THREE.WebGLRenderTarget
}
const renderTarget = new renderTargetClass(
  800,
  600,
  {
    minFilter: THREE.LinearFilter,
    magFilter: THREE.LinearFilter,
    format: THREE.RGBAFormat,
    encoding: THREE.sRGBEncoding,
  }
)

/* // - COMPOSER
const effectComposer = new EffectComposer(renderer, renderTarget)
effectComposer.setPixelRatio(Math.min(window.devicePixelRatio), 2)
effectComposer.setSize(sizes.width, sizes.height)

// -------- PASSES
const renderPass = new RenderPass(scene, camera)
effectComposer.addPass(renderPass)


const unrealBloomPass = new UnrealBloomPass()
unrealBloomPass.enabled = false
unrealBloomPass.strength = 0.7
unrealBloomPass.radius = 1
unrealBloomPass.threshold = 0.6
effectComposer.addPass(unrealBloomPass)
gui.add(unrealBloomPass, 'enabled').name('unreal bloom pass')
gui.add(unrealBloomPass, 'strength').min(0).max(1).step(0.005).name('unreal bloom strength')
gui.add(unrealBloomPass, 'radius').min(0).max(1).step(0.005).name('unreal bloom radius')
gui.add(unrealBloomPass, 'threshold').min(0).max(1).step(0.005).name('unreal bloom threshold') */
/**
 * Animate
 */
const clock = new THREE.Clock()

const tick = () =>
{
    const elapsedTime = clock.getElapsedTime()

    // Update controls
    controls.update()

   /*  if (sceneReady) {

      for(const point of points) {
        const screenPosition = point.position.clone();
        screenPosition.project(camera)
  
        raycaster.setFromCamera(screenPosition, camera)
        const intersects = raycaster.intersectObjects(scene.children, true)
  
        if( intersects.length === 0){
          point.element.classList.add('visible')
        }
        else {
          point.element.classList.remove('visible')
          const intersectionDistance = intersects[0].distance;
          const pointDistance = point.position.distanceTo(camera.position)
  
          if(intersectionDistance < pointDistance) {
            point.element.classList.remove('visible')
          }
          else {
            point.element.classList.add('visible')
          }
        }
  
        const translateX = screenPosition.x * sizes.width * 0.5
          const translateY = - screenPosition.y * sizes.height * 0.5
          point.element.style.transform = `translateX(${translateX}px) translateY(${translateY}px)`
        
        }
    }   */
    

    // Render
    renderer.render(scene, camera)
    //effectComposer.render(scene, camera)

    // Call tick again on the next frame
    window.requestAnimationFrame(tick)
}

tick()